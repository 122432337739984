@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat';
}

html,
body,
#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #032677;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #ff6900;
}

.ant-btn-primary {
  background: #ff6900 !important;
  border: none !important;
}

.ant-steps-item-icon {
  background: #ff6900 !important;
  border: none !important;
  color: #fff !important;
}

.ant-btn-primary:hover {
  filter: opacity(0.8);
}

.ant-btn:hover {
  filter: opacity(0.8);
}

.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: rgba(0,0,0,.2);
  cursor: pointer;
}



.slider-container {
  width: 100%;
  height: 100%;
  position: fixed; /* add this code for position fixed */
  top: 0px; /* set top and left 0 */
  left: 0px;
}

.slider,
.animated {
  width: 100% !important;
  height: 100% !important;
}

.slider-item-div {
  padding: 20px;
  background-color: white;
  text-align: center;
  height: 100%;
  width: 100%;
}

.carousel-slider {
  height: 100% !important;
}

.carousel .slide {
  background: #fff !important;
}

.slider-wrapper {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide .selected {
  height: 90% !important;
}

.dot {
  background: #2b7af9 !important;
  outline: none !important;
}

.MuiDialog-paper {
  border-radius: 25px !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #032677 !important;
  border-color: #032677 !important;
  color: #fff !important;
}

.ant-radio-button-wrapper:hover {
  color: #032677 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: #032677 !important;
}

