html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes slidein {
  0% {
    transform: translateX(100vw);
  }

  100%{
    transform: translateX(0);
  }
}
@keyframes slideout {
  0% {
    transform: translateX(100vw);
  }

  100%{
    transform: translateX(0);
  }
}


@keyframes fade {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
